// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selectContainer__ndrVr{margin:0 0 22px}.selectContainer__title__fWOKc{font-size:16px;color:#cccdcd;font-weight:700;margin:0 0 12px}`, "",{"version":3,"sources":["webpack://./src/components/UI/forms/SelectFormContainer/SelectFormContainer.module.scss"],"names":[],"mappings":"AAAA,wBACC,eAAA,CACA,+BACC,cAAA,CACA,aAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".selectContainer {\n\tmargin: 0 0 22px;\n\t&__title {\n\t\tfont-size: 16px;\n\t\tcolor: #CCCDCD;\n\t\tfont-weight: 700;\n\t\tmargin: 0 0 12px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectContainer": `selectContainer__ndrVr`,
	"selectContainer__title": `selectContainer__title__fWOKc`
};
export default ___CSS_LOADER_EXPORT___;
