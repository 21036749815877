// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input__zyINS{padding:9px 16px 8px;color:#cccdcd;font-size:16px;border-radius:4px;border:2px solid #20252b;background:#0f1215;display:block;width:100%}.input__zyINS::placeholder{color:#20252b}.input__zyINS:hover{box-shadow:0 0 5px #2a3342}.input__zyINS:focus{border-color:#2a3342}`, "",{"version":3,"sources":["webpack://./src/components/UI/forms/InputForm/InputForm.module.scss"],"names":[],"mappings":"AAAA,cACC,oBAAA,CACA,aAAA,CACA,cAAA,CACA,iBAAA,CACA,wBAAA,CACA,kBAAA,CACA,aAAA,CACA,UAAA,CACA,2BACC,aAAA,CAED,oBACC,0BAAA,CAED,oBACC,oBAAA","sourcesContent":[".input {\n\tpadding: 9px 16px 8px;\n\tcolor: #CCCDCD;\n\tfont-size: 16px;\n\tborder-radius: 4px;\n\tborder: 2px solid #20252B;\n\tbackground: #0F1215;\n\tdisplay: block;\n\twidth: 100%;\n\t&::placeholder {\n\t\tcolor: #20252B;\n\t}\n\t&:hover {\n\t\tbox-shadow: 0 0 5px rgb(42, 51, 66);\n\t}\n\t&:focus {\n\t\tborder-color: rgb(42, 51, 66);\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `input__zyINS`
};
export default ___CSS_LOADER_EXPORT___;
