// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.subStrate{position:fixed;left:0;top:0;right:0;bottom:0;background-color:rgba(0,0,0,.4);opacity:0;z-index:-1;overflow-y:auto;transition:.3s;display:flex;align-items:center;justify-content:center}.subStrate.active{opacity:1;z-index:1000}.modal__ib6is{background-color:#0b1729;box-shadow:0px 25px 40px rgba(0,0,0,.05);transition:.3s;width:auto;height:auto;position:relative;max-width:1000px}.crossWr__QGHa7{position:absolute;right:16px;top:16px;width:32px;height:32px;z-index:1}.cross__e4fGm{cursor:pointer;transition:.3s}.cross__e4fGm:hover{transform:scale(1.2) rotate(90deg)}`, "",{"version":3,"sources":["webpack://./src/components/common/PopUp/popUpTemp.module.scss"],"names":[],"mappings":"AAAA,WACE,cAAA,CACA,MAAA,CACA,KAAA,CACA,OAAA,CACA,QAAA,CACA,+BAAA,CACA,SAAA,CACA,UAAA,CACA,eAAA,CACA,cAAA,CAEA,YAAA,CACA,kBAAA,CACA,sBAAA,CAEF,kBACE,SAAA,CACA,YAAA,CAEF,cACE,wBAAA,CACA,wCAAA,CACA,cAAA,CACA,UAAA,CACA,WAAA,CAEA,iBAAA,CACA,gBAAA,CAEF,gBACE,iBAAA,CACA,UAAA,CACA,QAAA,CACA,UAAA,CACA,WAAA,CACA,SAAA,CAEF,cACE,cAAA,CACA,cAAA,CAEA,oBACE,kCAAA","sourcesContent":[":global(.subStrate) {\n  position: fixed;\n  left: 0;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  background-color: rgba(0, 0, 0, 0.4);\n  opacity: 0;\n  z-index: -1;\n  overflow-y: auto;\n  transition: 0.3s;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n:global(.subStrate.active) {\n  opacity: 1;\n  z-index: 1000;\n}\n.modal {\n  background-color: #0b1729;\n  box-shadow: 0px 25px 40px rgba(0, 0, 0, 0.05);\n  transition: 0.3s;\n  width: auto;\n  height: auto;\n\n  position: relative;\n  max-width: 1000px;\n}\n.crossWr {\n  position: absolute;\n  right: 16px;\n  top: 16px;\n  width: 32px;\n  height: 32px;\n  z-index: 1;\n}\n.cross {\n  cursor: pointer;\n  transition: 0.3s;\n\n  &:hover {\n    transform: scale(1.2) rotate(90deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `modal__ib6is`,
	"crossWr": `crossWr__QGHa7`,
	"cross": `cross__e4fGm`
};
export default ___CSS_LOADER_EXPORT___;
