// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main__MZVG7{height:auto}.body__VBa2r{margin:0 auto;max-width:1200px;padding:23px;border:1px solid orange}.title__EIDU6{font-size:22px}.content__RmDtH{margin:0 0 0 112px;height:300px;border:1px solid #ff0}`, "",{"version":3,"sources":["webpack://./src/components/containers/Main/Main.module.scss"],"names":[],"mappings":"AAAA,aACE,WAAA,CAGF,aACE,aAAA,CACA,gBAAA,CACA,YAAA,CACA,uBAAA,CAGF,cACE,cAAA,CAGF,gBACE,kBAAA,CACA,YAAA,CACA,qBAAA","sourcesContent":[".main {\n  height: auto;\n}\n\n.body {\n  margin: 0 auto;\n  max-width: 1200px;\n  padding: 23px;\n  border: 1px solid orange;\n}\n\n.title {\n  font-size: 22px;\n}\n\n.content {\n  margin: 0 0 0 112px;\n  height: 300px;\n  border: 1px solid yellow;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `main__MZVG7`,
	"body": `body__VBa2r`,
	"title": `title__EIDU6`,
	"content": `content__RmDtH`
};
export default ___CSS_LOADER_EXPORT___;
