// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.inputContainer__JV8OR{margin:0 0 22px}.inputContainer__title__eA5Pr{font-size:16px;color:#cccdcd;font-weight:700;margin:0 0 12px}`, "",{"version":3,"sources":["webpack://./src/components/UI/forms/InputFormContainer/InputFormContainer.module.scss"],"names":[],"mappings":"AAAA,uBACC,eAAA,CACA,8BACC,cAAA,CACA,aAAA,CACA,eAAA,CACA,eAAA","sourcesContent":[".inputContainer {\n\tmargin: 0 0 22px;\n\t&__title {\n\t\tfont-size: 16px;\n\t\tcolor: #CCCDCD;\n\t\tfont-weight: 700;\n\t\tmargin: 0 0 12px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"inputContainer": `inputContainer__JV8OR`,
	"inputContainer__title": `inputContainer__title__eA5Pr`
};
export default ___CSS_LOADER_EXPORT___;
