// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container__SWDdI{margin:0 auto;max-width:1262px;padding:0 23px}`, "",{"version":3,"sources":["webpack://./src/components/containers/Container/Container.module.scss"],"names":[],"mappings":"AAAA,kBACC,aAAA,CACA,gBAAA,CACA,cAAA","sourcesContent":[".container {\n\tmargin: 0 auto;\n\tmax-width: 1262px;\n\tpadding: 0 23px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `container__SWDdI`
};
export default ___CSS_LOADER_EXPORT___;
